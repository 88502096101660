<template>
  <div class="datatable-per-page">
    <span>Lignes affichées</span>
    <FormSelect @input="change"
                class="per-page-option"
                id="per-page"
                name="per-page"
                :options="options"
                :selected-at-startup="perPage"
                value-to-select="value"
                :show-icon-down-mini="true"/>
  </div>
</template>

<script>
import FormSelect from '@/components/FormSelect'

export default {
  components: {
    FormSelect
  },
  props: {
    perPage: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      options: [
        {
          name: 5,
          value: 5
        },
        {
          name: 10,
          value: 10
        },
        {
          name: 20,
          value: 20
        },
        {
          name: 50,
          value: 50
        },
        {
          name: 100,
          value: 100
        },
        {
          name: 300,
          value: 300
        }
      ]
    }
  },
  methods: {
    change (perPage) {
      this.$emit('change', parseInt(perPage))
    }
  }
}
</script>

<style lang="scss" scoped>
  .datatable-per-page {
    display: inline-block;
    margin-right: 25px;
    font-size: 12px;

    @media (max-width: $breakpoint-md) {
      font-size: 11px;
    }
  }

  span {
    margin-right: 20px;

    @media (max-width: $breakpoint-md) {
      margin-right: 15px;
    }
  }

  .per-page-option {
    display: inline-block;

    .form-control {
      border-bottom: none !important;
    }
  }
</style>
