<template>
  <div class="datatable-page-info">
    {{ from }}-{{ to }} sur {{ totalCount }}
  </div>
</template>

<script>
export default {
  props: {
    totalCount: {
      type: Number,
      required: true
    },
    from: {
      type: Number,
      required: true
    },
    to: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  .datatable-page-info {
    display: inline-block;
    margin-right: 20px;
    font-size: 12px;

    @media (max-width: $breakpoint-md) {
      font-size: 11px;
    }
  }
</style>
