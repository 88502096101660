<template>
  <div style="flex-direction: column;">
    <snackbar ref="snackbar" base-size="100px"></snackbar>
    <div class="page-content">
      <DataCrudTable
        ref="crudTable"
        :filters="[]"
        :headers="headers"
        :options="options"
        endpoint="/user/"
        endpoint-key="users"
        :row-click="showUser"
        selectable
        @selected="x => { selected = x; }"
        :add-click="clickCreate"
        :delete-click="clickDelete"
      >
      </DataCrudTable>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import DataCrudTable from '@/components/DataCrudTable'
import client from '@/api/premat/client'
import * as store from '@/store/user'

export default {
  components: {
    DataCrudTable
  },
  data () {
    return {
      selected: [],
      items: [],
      options: {
        uniqueKey: 'id',
        texts: {
          noResults: 'Aucune autorisation correspondante'
        },
        skin: 'table table-striped table-hover',
        rowClassCallback: row => {
          return this.selected.includes(row.id.toString()) ? 'selected' : ''
        },
        cellClasses: {
          checkbox: [
            {
              class: 'checkbox-cell',
              condition: () => true
            }
          ]
        }
      }
    }
  },
  computed: {
    headers () {
      return [
        { name: 'id', label: 'Id', heading: 'Id', sortable: true, show: true, type: 'String' },
        { name: 'login', label: 'Identifiant exploitant', heading: 'Identifiant exploitant', sortable: true, show: true, type: 'String' },
        { name: 'mail', label: 'Mail', heading: 'Mail', sortable: true, show: true, type: 'String' },
        { name: 'access', label: 'Privilège', heading: 'Privilège', sortable: true, show: true, type: 'String' },
        { name: 'activated', label: 'Activé', heading: 'Activé', sortable: true, show: true, type: 'Boolean' }
      ]
    }
  },
  methods: {
    clickCreate () {
      const user = {
        access: null,
        activated: true,
        id: null,
        login: null,
        mail: null
      }

      this.$dialog.confirm(user, {
        view: 'user-form-dialog', // can be set globally too
        html: true,
        animation: 'fade',
        backdropClose: false,
        editMode: false,
        data: _.cloneDeep(user)
      }).then(res => {
        const value = _.cloneDeep(res.data)
        value.activated = value.activated ? 1 : 0
        client.post('/user/', { data: JSON.stringify(value) })
          .then(res2 => {
            this.$refs.snackbar.info(`L'utilisateur ${res2.data.user.login} a été créé.`)
            this.$refs.crudTable.debouncedGetItems()
          })
          .catch(err => {
            this.$refs.snackbar.error(err.response.data.message)
          })
      })
    },
    clickDelete () {
      this.$dialog
        .confirm('Voulez-vous supprimer tous les utilisateurs sélectionnés ?', { okText: 'Confirmer', cancelText: 'Annuler' })
        .then(dialog => {
          var promises = []
          this.selected.forEach(x => {
            if (store.default.state.user.user.id != x) {
              promises.push(client.delete(`/user/${x}/`)
                .then(res2 => {
                  this.$refs.snackbar.info(`L'utilisateur ${x} a été supprimé.`)
                  this.$refs.crudTable.debouncedGetItems()
                })
                .catch(err => {
                  this.$refs.snackbar.error(err.response.data.message)
                }))
            }
          })
          Promise.all(promises).finally(() => {
            this.$refs.crudTable.debouncedGetItems()
          })
        })
        .catch(() => {
          console.log('Clicked on cancel')
        })
    },
    showUser (click) {
      if (click.event.target.classList.contains('icon')
        || click.event.target.classList.contains('authorization-checkbox')
        || click.event.target.classList.contains('checkbox-cell')) {
        return
      }
      const user = _.cloneDeep(click.row)
      user.activated = user.activated === 1
      this.$dialog.confirm(user, {
        view: 'user-form-dialog', // can be set globally too
        html: true,
        animation: 'fade',
        backdropClose: false,
        editMode: true,
        data: _.cloneDeep(user)
      }).then(res => {
        const value = _.cloneDeep(res.data)
        value.activated = value.activated ? 1 : 0
        client.put(`/user/${value.id}/`, { data: JSON.stringify(value) })
          .then(res2 => {
            this.$refs.snackbar.info(`L'utilisateur ${res2.data.user.login} a été modifié.`)
            this.$refs.crudTable.debouncedGetItems()
          })
          .catch(err => {
            this.$refs.snackbar.error(err.response.data.message)
          })
      })
    }
  }
}
</script>

<style language="scss">
  .page-content {
    padding-top: 2em;
  }
</style>
