var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row",staticStyle:{"margin-left":"0px","margin-bottom":"20px"}},[_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"row"},[(_vm.addClick)?_c('button',{staticClass:"btn btn-secondary",staticStyle:{"margin-right":"10px"},on:{"click":_vm.addClick}},[_vm._v("Ajouter")]):_vm._e(),(_vm.deleteClick)?_c('button',{staticClass:"btn btn-secondary",staticStyle:{"margin-right":"10px"},on:{"click":_vm.deleteClick}},[_vm._v("Supprimer")]):_vm._e(),_vm._l((_vm.localFilters),function(f,index){return [_c('div',{key:index,staticClass:"col-md-3",staticStyle:{"padding":"0px 5px 0px 0px"}},[_c('FormInputSelect',{attrs:{"items":f.items,"label":f.label,"placeholder":f.placeholder,"item-value":'value',"item-text":function (x) { return x.value; },"clearable":""},model:{value:(f.value),callback:function ($$v) {_vm.$set(f, "value", $$v)},expression:"f.value"}})],1)]})],2)]),_c('div',{staticClass:"col-xl-6 text-xl-right",staticStyle:{"margin-top":"18px"}},[_c('div',{staticClass:"col-xl-6 text-xl-right"},[(_vm.authorizations)?_c('DataTableVisualizeButton',{attrs:{"selected":_vm.selected}}):_vm._e(),_c('DataTableColumnsSelector',{attrs:{"headers":_vm.localHeaders},on:{"columns-change":function (v) { _vm.localHeaders = v; }}}),(_vm.authorizations)?_c('DataTableSearch',{on:{"input":function (x) { _vm.search = x; }}}):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-client-table',{ref:"datatable",attrs:{"data":!_vm.allDisplayed ? _vm.pageOfItems : _vm.items,"columns":_vm.localColumns,"options":_vm.localOptions},on:{"row-click":_vm.rowClick},scopedSlots:_vm._u([{key:"checkbox",fn:function(ref){
var row = ref.row;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"authorization-checkbox",attrs:{"id":row.id.toString(),"type":"checkbox"},domProps:{"value":row.id.toString(),"checked":Array.isArray(_vm.selected)?_vm._i(_vm.selected,row.id.toString())>-1:(_vm.selected)},on:{"click":_vm.toggleCheckbox,"change":function($event){var $$a=_vm.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=row.id.toString(),$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selected=$$c}}}})]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('AuthorizationStatusBadge',{attrs:{"authorization":row}})]}},{key:"pdf",fn:function(ref){
var row = ref.row;
return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.getPdf(row)}}},[_c('i',{staticClass:"icon icon-PDF"})])]}},{key:"activated",fn:function(ref){
var row = ref.row;
return [(row.activated)?_c('i',{staticClass:"icon-checkmark"}):_c('i',{staticClass:"icon-cross2"})]}}])}),_c('div',{staticClass:"row",staticStyle:{"width":"100%","text-align":"center","justify-content":"center"}},[(!_vm.allDisplayed)?_c('jw-pagination',{key:_vm.perPage,attrs:{"items":_vm.items,"page-size":_vm.perPage},on:{"changePage":function (v) { _vm.pageOfItems = v; }}}):_vm._e(),_c('FormInputSelect',{staticClass:"form-input-select",staticStyle:{"width":"100px","height":"32px","margin-left":"20px"},attrs:{"items":_vm.itemsPerPageOptions,"item-text":function (x) { return x.label; },"item-value":"value"},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }