<template>
    <div class="container-fluid">
        <div class="row" style="margin-left: 0px; margin-bottom: 20px;">
            <div class="col-xl-6">
                <div class="row">
                    <button v-if="addClick" class="btn btn-secondary" style="margin-right: 10px" @click="addClick">Ajouter</button>
                    <button v-if="deleteClick" class="btn btn-secondary" style="margin-right: 10px" @click="deleteClick">Supprimer</button>
                    <template v-for="(f, index) in localFilters">
                        <div class="col-md-3" style="padding: 0px 5px 0px 0px;" :key="index">
                            <FormInputSelect
                                v-model="f.value"
                                :items="f.items"
                                :label="f.label"
                                :placeholder="f.placeholder"
                                :item-value="'value'"
                                :item-text="x => x.value"
                                clearable
                            >
                            </FormInputSelect>
                        </div>
                    </template>
                </div>
            </div>
            <div class="col-xl-6 text-xl-right" style="margin-top: 18px;" >
                <div class="col-xl-6 text-xl-right">
                    <DataTableVisualizeButton v-if="authorizations" :selected="selected"/>
                    <DataTableColumnsSelector :headers="localHeaders" @columns-change="v => { localHeaders = v; }"/>
                    <DataTableSearch v-if="authorizations" @input="x => { search = x; }"/>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <v-client-table ref="datatable"
                                :data="!allDisplayed ? pageOfItems : items"
                                :columns="localColumns"
                                :options="localOptions"
                                @row-click="rowClick"
                >
                    <template slot="checkbox" slot-scope="{ row }">
                        <input :id="row.id.toString()"
                               v-model="selected"
                               type="checkbox"
                               :value="row.id.toString()"
                               class="authorization-checkbox"
                               @click="toggleCheckbox"
                        >
                    </template>
                    <template slot="status" slot-scope="{ row }">
                        <AuthorizationStatusBadge :authorization="row"></AuthorizationStatusBadge>
                    </template>
                    <template slot="pdf" slot-scope="{ row }">
                        <a href="#"
                           @click.prevent="getPdf(row)"
                        >
                            <i class="icon icon-PDF" />
                        </a>
                    </template>
                    <template slot="activated" slot-scope="{ row }">
                        <i v-if="row.activated" class="icon-checkmark"></i>
                        <i v-else class="icon-cross2"></i>
                    </template>
                </v-client-table>
                <div class="row" style="width: 100%; text-align: center; justify-content: center;">
                    <jw-pagination v-if="!allDisplayed" :key="perPage" :items="items" :page-size="perPage" @changePage="v => { pageOfItems = v; }"></jw-pagination>
                    <FormInputSelect
                        v-model="perPage"
                        class="form-input-select"
                        style="width: 100px; height: 32px; margin-left: 20px;"
                        :items="itemsPerPageOptions"
                        :item-text="x => x.label"
                        item-value="value"
                    ></FormInputSelect>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import $ from 'jquery'
import AuthorizationStatusBadge from '@/components/AuthorizationStatusBadge'
import DataTablePerPage from '@/components/DataTablePerPage'
import DataTablePageInfo from '@/components/DataTablePageInfo'
import DataTablePagination from '@/components/DataTablePagination'
import DataTableVisualizeButton from '@/components/DataTableVisualizeButton'
import DataTableColumnsSelector from '@/components/DataTableColumnsSelector'
import DataTableSearch from '@/components/DataTableSearch'
import FormInputSelect from '@/components/FormInputSelect'
import { formatDate, formatWeight, formatDimensions } from '@/utils'
import { getAuthorizationPdf } from '@/api/premat'
import client from '@/api/premat/client'
import qs from 'qs'
import JwPagination from 'jw-vue-pagination'

export default {
  name: 'DataCrudTable',
  components: {
    AuthorizationStatusBadge,
    DataTablePerPage,
    DataTablePageInfo,
    DataTablePagination,
    FormInputSelect,
    DataTableVisualizeButton,
    DataTableColumnsSelector,
    DataTablePagination,
    DataTableSearch,
    JwPagination
  },
  props: {
    headers: { type: Array, required: true },
    options: { type: null, required: true },
    endpoint: { type: String, default: undefined },
    endpointKey: { type: String, default: '' },
    query: { type: Object, default: () => {} },
    maxHeight: { type: String, default: undefined },
    displayGrid: { type: Boolean, default: false },
    itemsPerPage: { type: Number, default: 10 },
    filters: { type: Array, default: () => {} },
    adapter: { type: Function, default: (res) => { return res } },
    rowClick: { type: Function, default: () => {} },
    selectable: { type: Boolean, default: false },
    action: { type: Boolean, default: false },
    authorizations: { type: Boolean, default: false },
    addClick: { type: Function, default: undefined },
    deleteClick: { type: Function, default: undefined }
  },
  data () {
    return {
      localHeaders: _.cloneDeep
      (this.$store.state.user.columns === null
                            || this.$route.path !== '/authorizations'
        ? this.headers : this.$store.state.user.columns),
      pageOfItems: [],
      selected: [],
      selectedAll: false,
      loading: false,
      items: [],
      defaultModel: {},
      page: 1,
      localItemsPerPage: this.itemsPerPage,
      localOptions: _.cloneDeep(this.options),
      localFilters: _.cloneDeep(this.filters),
      pageCount: 1,
      pagination: {},
      search: '',
      perPage: this.itemsPerPage
    }
  },
  created () {
    this.debouncedGetItems()
  },
  computed: {
    columns () {
      const columns = []
      _.forEach(this.localHeaders, h => {
        if (h.show) {
          columns.push(h.name)
        }
      })
      return columns
    },
    columnDisplayList () {
      return _.map(this.localHeaders, x => x.name)
    },
    allDisplayed () {
      return this.perPage === -1 || this.items.length < this.perPage
    },
    itemsPerPageOptions () {
      return [
        { value: 5, label: '5' },
        { value: 10, label: '10' },
        { value: 20, label: '20' },
        { value: 50, label: '50' },
        { value: 100, label: '100' },
        { value: -1, label: 'Tous' }
      ]
    },
    debouncedGetItems () {
      return _.debounce(this.getItems, 400)
    },
    requestId () {
      return this.$uid()
    },
    requestCountId () {
      return this.$uid()
    },
    localColumns () {
      let columns = _.cloneDeep(this.columns)
      if (this.selectable) {
        columns = ['checkbox'].concat(columns)
      } else if (this.action) {
        // TODO
      }
      return columns
    }
  },
  watch: {
    selectedAll () {
      if (this.selectedAll) {
        _.forEach(this.items, item => {
          this.selected.push(item.id)
        })
      } else {
        this.selected = []
      }
    },
    endpoint () {
      this.refresh()
    },
    query () {
      this.debouncedGetItems()
    },
    options: {
      handler (v) {
        this.localOptions = this.setOptions(this.headers)
        this.debouncedGetItems()
      },
      deep: true
    },
    itemsPerPage () {
      this.debouncedGetItems()
    },
    page () {
      this.debouncedGetItems()
    },
    headers: {
      deep: true,
      handler (headers) {
        this.localHeaders = _.cloneDeep(headers)
      }
    },
    localHeaders: {
      deep: true,
      handler (headers) {
        this.localOptions = this.setOptions(this.headers)
        this.$store.commit('user/SET_COLUMNS', headers)
      }
    },
    selected (values) {
      this.$emit('selected', values)
    }
  },
  created () {
    this.localOptions = this.setOptions(this.headers)
    this.debouncedGetItems()
  },
  methods: {
    callback: function (page) {
      console.log(`Page ${page} was selected. Do something about it`)
    },
    log (o) {
      console.log('[LOG CRUD]', o)
    },
    setFilterItems (items) {
      _.forEach(items, item => {
        _.forEach(this.localFilters, filter => {
          if (!filter.items.some(x => x.value === item[filter.itemValue])) {
            filter.items.push({ value: item[filter.itemValue] })
          }
        })
      })
      _.forEach(this.localFilters, filter => {
        filter.items = _.orderBy(filter.items, ['value'], ['asc'])
      })
    },
    columnsChange (columns) {
      const finalColumns = []
      const finalSortable = []
      columns.forEach(column => {
        if (column.name !== 'checkbox' && column.name !== 'action') {
          if (column.show) {
            finalColumns.push(column.name)
            finalSortable.push(column.name)
          }
        }
      })
      this.columns = _.cloneDeep(finalColumns)
      this.localOptions.sortable = _.cloneDeep(finalSortable)
    },
    toggleCheckbox (event) {
      const selected = this.selected
      const id = event.target.getAttribute('id').toString()
      if (event.target.checked === true && !selected.includes(id)) {
        selected.push(id)
      } else if (event.target.checked === false && selected.includes(id)) {
        selected.splice(selected.indexOf(id), 1)
      }
      this.selected = selected
    },
    getPdf (authorization) {
      getAuthorizationPdf(authorization)
    },
    getItems () {
      console.log('ITEMS =', this.items)
      this.loading = true
      const params = {}

      client.get(this.endpoint, {
        params
      })
        .then(res => {
          if (this.$route.path !== '/authorizations') {
            this.items = this.adapter(res.data[this.endpointKey])
            return
          }

          this.$watch('localFilters', function (o, n) {}, { deep: true })
          this.$watch('search', function (o, n) {}, { deep: true })
          const items = this.adapter(res.data[this.endpointKey])
          this.setFilterItems(items)
          let some_filters_set = false
          if (this.$store.state.user.filter_city_start != null) {
            this.localFilters[0].value = this.$store.state.user.filter_city_start
            some_filters_set = true
          }
          if (this.$store.state.user.filter_city_end != null) {
            this.localFilters[1].value = this.$store.state.user.filter_city_end
            some_filters_set = true
          }
          if (this.$store.state.user.filter_tonnage != null) {
            this.localFilters[2].value = this.$store.state.user.filter_tonnage
            some_filters_set = true
          }
          if (this.$store.state.user.search != null) {
            this.search = this.$store.state.user.search
            $('#table-search').val(this.$store.state.user.search)
            some_filters_set = true
          }
          if (some_filters_set) {
            this.requestItem()
          } else {
            this.items = items
          }
          this.$watch('localFilters', function (o, n) {
            this.requestItem()
            this.$store.commit('user/SET_FILTERS',
              [
                this.localFilters[0].value,
                this.localFilters[1].value,
                this.localFilters[2].value
              ])
          }, { deep: true })
          this.$watch('search', function (o, n) {
            const newValue = $('#table-search').val()
            this.requestItem()
            if (newValue === '' || newValue == null
                      || newValue == undefined) {
              this.$store.commit('user/SET_SEARCH', null)
            } else {
              this.$store.commit('user/SET_SEARCH', newValue)
            }
          }, { deep: true })
        })
    },
    updatePerPage (perPage) {
      this.perPage = perPage
    },
    updatePage (page) {
      this.$refs.datatable.setPage(page)
    },
    requestItem () {
      const params = {
        limit: this.perPage,
        page: this.page,
        search: this.search,
        order: 'asc'
      }

      _.forEach(this.columns, col => {
        if (col === 'city_start') {
          params.order_by = 'city_start'
        } else if (col === 'city_end') {
          params.order_by = 'city_end'
        } else if (col === 'dimensions') {
          params.order_by = []
          params.order_by.push('length')
          params.order_by.push('width')
          params.order_by.push('height')
        } else if (col === 'weight') {
          params.order_by = []
          params.order_by.push('tonne')
          params.order_by.push('kilogram')
        } else {
          params.order_by = []
          params.order_by.push('num')
        }
      })

      _.forEach(this.localFilters, filter => {
        params[filter.itemValue] = filter.value
      })

      return client
        .get(this.endpoint, {
          params,
          paramsSerializer: params => {
            const finalParams = {}
            for (const key in params) {
              if (Object.prototype.hasOwnProperty.call(params, key)) {
                if (params[key] !== null && params[key] !== '') {
                  finalParams[key] = params[key]
                }
              }
            }
            return qs.stringify(finalParams, { arrayFormat: 'repeat' })
          }
        })
        .then(res => {
          this.items = this.adapter(res.data[this.endpointKey])
        })
    },
    setOptions (headers) {
      const sortable = []
      const headings = {}
      const options = _.cloneDeep(this.options)
      options.headings = {}
      if (this.selectable) {
        headings.checkbox = h => <input type="checkbox" name="all" id="all" on-click={ () => { this.selectedAll = !this.selectedAll }}/>
      } else if (this.action) {
        // TODO
      }
      _.forEach(headers, h => {
        headings[h.name] = h.heading
        if (h.sortable) {
          sortable.push(h.name)
        }
      })
      options.sortable = _.cloneDeep(sortable)
      options.headings = _.cloneDeep(headings)
      options.perPage = Number.MAX_VALUE // Need to set a max value for table row display
      return options
    }
  }
}
</script>

<style lang="scss">
.premat-crud-table-wrapper {
  margin: 0px 15px;
}

.col-xl-6 {
    max-width: 100%;
}

.dg-content-cont--floating  {
    -webkit-transform: translateY(-25%) !important;
    transform: translateY(-25%) !important;
}

.VuePagination {
  text-align: center;
}
.vue-pagination-ad {
  text-align: center;
}

.form-input-select #vs4__combobox {
    height: 32px;
}

.VueTables__table {
    margin-bottom: 20px;
    font-size: 13px;

    @media (max-width: $breakpoint-md) {
      font-size: 11px;
    }
  }

  .VueTables__heading .icon-download {
    font-size: 24px;

    @media (max-width: $breakpoint-md) {
      font-size: 18px;
      vertical-align: -8px;
    }
  }

  .VueTables__sortable {
    height: 50px;

    &:focus {
      outline: none;
    }
  }

  .VueTables__row:hover {
    cursor: pointer;
  }

  .VueTables__sort-icon {
    top: 0;
    right: -12px;
    width: 20px;
    height: 50px !important;
    line-height: 50px !important;

    &.icon-up-mini {
      top: -2px;
      right: -5px;
    }
  }

  .VueTables__search,
  .VueTables__limit,
  .VuePagination {
    display: none;
  }

  .VueTables__row.selected {
    background: #fbeef1 !important;
  }
</style>
