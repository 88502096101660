<template>
  <div class="authorizations-filters container-fluid">
    <div class="row">
      <div class="col-xl-6">
        <div class="row">
          <div class="col-md-3" style="padding: 0px 2px 0px 10px;">
            <FormInputSelect
              v-model="cityStart"
              label="Ville départ"
              placeholder="Ville départ"
              item-value="city_start"
              :item-text="x => x.city_start"
              clearable/>
          </div>
          <div class="col-md-3" style="padding: 0px 2px;">
            <FormInputSelect
              v-model="cityEnd"
              label="Ville arrivée"
              placeholder="Ville arrivée"
              item-value="city_end"
              :item-text="x => x.city_end"
              clearable
              @input="x => cityEnd=x"/>
          </div>
          <div class="col-md-3" style="padding: 0px 2px;">
            <FormInputSelect
              v-model="tonnage"
              label="Tonnage"
              placeholder="Tonnage"
              :items="tonnages"
              item-value="tonnage"
              :item-text="x => x.tonnage"
              clearable
              @input="x => tonnage=x"/>
          </div>
          <div class="col-md-3" style="padding: 0px 5px;">
            <button @click="submit"
                    :disabled="filterButtonDisabled"
                    class="btn btn-secondary">
              Filtrer
            </button>
          </div>
          <div class="col-md-3" style="padding: 0px 5px;">
            <button v-if="isFiltering"
                    @click="reset"
                    class="btn btn-outline-secondary">
              Réinitialiser
            </button>
          </div>
        </div>
      </div>
      <div class="col-xl-6 text-xl-right">
        <DataTableVisualizeButton :selected="selected"/>
        <DataTableColumnsSelector @columns-change="columnsChange"/>
        <DataTableSearch @input="search"/>
      </div>
    </div>
  </div>
</template>

<script>
import FormCityAutocomplete from '@/components/FormCityAutocomplete'
import FormInputSelect from '@/components/FormInputSelect'
import FormSelect from '@/components/FormSelect'
import DataTableVisualizeButton from '@/components/DataTableVisualizeButton'
import DataTableColumnsSelector from '@/components/DataTableColumnsSelector'
import DataTableSearch from '@/components/DataTableSearch'
import { listCategories } from '@/api/premat'

export default {
  components: {
    FormCityAutocomplete,
    FormSelect,
    DataTableVisualizeButton,
    DataTableColumnsSelector,
    DataTableSearch,
    FormInputSelect
  },
  props: {
    selected: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      tonnages: [],
      cityStart: null,
      cityEnd: null,
      tonnage: null,
      isFiltering: false
    }
  },
  computed: {
    filterButtonDisabled () {
      return !(this.cityStart || this.cityEnd || this.tonnage)
    }
  },
  methods: {
    log (o) {
      console.log('LOG:', o)
    },
    getTonnages () {
      listCategories()
        .then(response => {
          this.tonnages = response.data.categories
        })
        .catch((error) => console.log(error))
    },
    submit () {
      this.isFiltering = true
      this.$emit('input', {
        cityStart: this.cityStart,
        cityEnd: this.cityEnd,
        tonnage: this.tonnage
      })
    },
    reset () {
      window.location.reload(true)
    },
    columnsChange (columns) {
      this.$emit('columns-change', columns)
    },
    search (text) {
      this.$emit('search', text)
    }
  },
  mounted () {
    this.getTonnages()
  }
}
</script>

<style scoped lang="scss">
  .authorizations-filters {
    margin-bottom: 40px;

    @media (max-width: $breakpoint-md) {
      margin-bottom: 10px;
    }
  }

  .btn {
    @media (max-width: $breakpoint-lg) {
      width: 100%;
    }
  }
</style>
