<template>
  <div class="columns-selector">
    <div v-click-outside="hide"
         @click="toggleList"
         class="columns-selector-button"
         role="button">
      <img src="../assets/images/columns.svg" alt="colonnes"> Colonnes
    </div>
    <div v-if="showList"
         class="columns-selector-list">
      <div v-for="(column, index) in columns"
           :key="index"
           :class="columnClass(index)"
           @click="toggleColumn(index)">
        {{ column.label }}
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside
  },
  props: {
    headers: { type: Array, required: true }
  },
  data () {
    return {
      showList: false,
      columns: this.headers
    }
  },
  created () {
    console.log('headers', this.headers)
  },
  methods: {
    hide () {
      this.showList = false
    },
    toggleList () {
      this.showList = !this.showList
    },
    toggleColumn (index) {
      this.columns[index].show = !this.columns[index].show
    },
    columnClass (index) {
      return {
        'columns-selector-column': true,
        inactive: !this.columns[index].show
      }
    }
  },
  watch: {
    columns: {
      deep: true,
      handler () {
        this.$emit('columns-change', this.columns)
      }
    }
  },
  mounted () {
    this.popupItem = this.$el
    this.$emit('columns-change', this.columns)
  }
}
</script>

<style scoped lang="scss">
  .columns-selector {
    display: inline-block;
    margin-right: 7px;
    position: relative;

    @media (max-width: $breakpoint-sm) {
      margin-top: 10px;
      width: 49%;
    }

    @media (max-width: $breakpoint-xs) {
      width: 100%;
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  .columns-selector-button {
    display: inline-block;
    font-size: 15px;
    padding: 10px 20px 10px 17px;
    background: $gray-lighter;
    border: 1px solid $gray-light;
    border-radius: 3px;
    height: 42px;

    @media (max-width: $breakpoint-md) {
      font-size: 13px;
      padding: 7px 15px;
    }

    @media (max-width: $breakpoint-sm) {
      text-align: center;
      width: 100%;
    }

    &:hover {
      cursor: pointer;
    }

    img {
      margin-right: 5px;

      @media (max-width: $breakpoint-md) {
        margin-right: 3px;
        width: 15px;
        height: auto;
        vertical-align: -1px;
      }
    }
  }

  .columns-selector-list {
    position: absolute;
    top: 42px;
    left: -100px;
    width: 292px;
    background: $white;
    border: 1px solid #979797;
    padding: 15px;
    z-index: 999;

    @media (max-width: $breakpoint-md) {
      padding: 12px;
    }

    @media (max-width: $breakpoint-sm) {
      left: 0;
      width: 100%;
    }
  }

  .columns-selector-column {
    background: $red;
    color: white !important;
    border: 1px solid $gray-light;
    text-align: center;
    padding: 10px 15px;
    margin-bottom: 8px;
    font-size: 14px;

    @media (max-width: $breakpoint-md) {
      font-size: 12px;
      padding: 7px 12px;
      margin-bottom: 6px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      cursor: pointer;
    }

    &.inactive {
      background: $gray-lighter !important;
      color: black !important;
    }
  }
</style>
