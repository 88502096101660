<template>
  <div class="visualize">
    <div @click="visualize"
         :class="visualizeButtonClasses">
      <i class="icon-btn-view"></i> Visualiser <span>la sélection</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: Array,
      required: true
    }
  },
  computed: {
    visualizeButtonClasses () {
      return {
        'visualize-button': true,
        disabled: !this.selected.length
      }
    }
  },
  methods: {
    visualize () {
      if (!this.selected.length) return
      this.$router.push({
        name: 'authorizations.visualize',
        query: {
          authorizations: this.selected
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .visualize {
    display: inline-block;
    margin-right: 7px;

    @media (max-width: $breakpoint-sm) {
      margin-top: 10px;
      width: 100%;
    }

    @media (max-width: $breakpoint-xs) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .visualize-button {
    display: inline-block;
    font-size: 15px;
    padding: 10px 20px 10px 17px;
    background: $gray-lighter;
    border: 1px solid $gray-light;
    border-radius: 3px;
    height: 42px;

    @media (max-width: $breakpoint-md) {
      font-size: 13px;
      padding: 7px 15px;
    }

    @media (max-width: $breakpoint-sm) {
      text-align: center;
      width: 100%;
    }

    &:hover {
      cursor: pointer;
    }

    i {
      color: $red;
      font-size: 18px;
      vertical-align: -2px;
      margin-right: 5px;

      @media (max-width: $breakpoint-md) {
        font-size: 16px;
        margin-right: 3px;
      }
    }

    &.disabled {
      opacity: .5;

      &:hover {
        cursor: not-allowed;
      }
    }

    span {
      display: none;

      @media (max-width: $breakpoint-lg) {
        display: inline;
      }
    }
  }
</style>
