<template>
  <div class="authorizations-index-page">
    <AuthorizationsStatistics/>
    <div class="page-content">
      <DataCrudTable :data="items"
                 :filters="filters"
                 :headers="headers"
                 :options="options"
                 endpoint="/authorization/"
                 endpoint-key="authorizations"
                 :adapter="responseAdapter"
                 @selected="x => selected = x"
                 selectable
                 authorizations
                 :row-click="showAuthorization"
      >
      </DataCrudTable>
    </div>
  </div>
</template>

<script>
import AuthorizationsStatistics from '@/components/AuthorizationsStatistics'
import DataTableFilters from '@/components/DataTableFilters'
import DataCrudTable from '@/components/DataCrudTable'
import { formatDate, formatWeight, formatDimensions } from '@/utils'

export default {
  name: 'AuthorizationsIndexPage',
  components: {
    AuthorizationsStatistics,
    DataTableFilters,
    DataCrudTable
  },
  data () {
    return {
      selected: [],
      items: [],
      options: {
        uniqueKey: 'id',
                orderBy: {
          ascending: true,
          column: 'num',
        },
        sortIcon: {
          base: ''
          // up: 'icon-up-mini',
          // down: 'icon-down-mini'
        },
        texts: {
          noResults: 'Aucune autorisation correspondante'
        },
        skin: 'table table-striped table-hover',
        rowClassCallback: row => {
          return this.selected.includes(row.id.toString()) ? 'selected' : ''
        },
        cellClasses: {
          checkbox: [
            {
              class: 'checkbox-cell',
              condition: () => true
            }
          ]
        }
      }
    }
  },
  computed: {
    filters () {
      return [
        { value: null, label: 'Ville départ', placeholder: 'Ville départ', itemValue: 'city_start', itemText: x => x.city_start, items: [] },
        { value: null, label: 'Ville arrivée', placeholder: 'Ville arrivée', itemValue: 'city_end', itemText: x => x.city_end, items: [] },
        { value: null, label: 'Tonnage', placeholder: 'Tonnage', itemValue: 'tonnage', itemText: x => x.tonnage, items: [] }
      ]
    },
    headers () {
      return [
        { name: 'id', label: 'Id', heading: 'Id', sortable: true, show: true },
        { name: 'num', label: 'N°', heading: 'N°', sortable: true, show: true },
        { name: 'city_start', label: 'Départ', heading: 'Départ', sortable: true, show: true },
        { name: 'city_end', label: 'Arrivée', heading: 'Arrivée', sortable: true, show: true },
        { name: 'tonnage', label: 'Tonnage', heading: 'Tonnage', sortable: true, show: true },
        { name: 'weight', label: 'Poids', heading: 'Poids', sortable: true, show: false },
        { name: 'num_authorization_on_pdf', label: 'N° autorisation', heading: h => <span>N°&nbsp;autorisation</span>, sortable: true, show: false },
        { name: 'dimensions', label: 'L. x l. x h. (en m)', heading: 'L. x l. x h. (en m)', sortable: true, show: true },
        { name: 'date_start', label: 'Début Validité', heading: 'Début Validité', sortable: true, show: true },
        { name: 'date_end', label: 'Fin Validité', heading: 'Fin Validité', sortable: true, show: true },
        { name: 'renewal_date_last_status', label: 'Renouvelée le', heading: h => <span>Renouvelée&nbsp;le</span>, sortable: true, show: false },
        { name: 'renewal_date_ask', label: 'Demande de renouvellement', heading: h => <span>Demande&nbsp;de renouvellement</span>, sortable: true, show: false },
        { name: 'renewal_num', label: 'N° de demande de renouvellement', heading: h => <span>N°&nbsp;demande&nbsp;de renouvellement</span>, sortable: true, show: false },
        { name: 'status', label: 'Status', heading: 'Statut', sortable: true, show: true },
        { name: 'pdf', label: 'PDF', heading: h => <i class="icon-download"></i>, sortable: false, show: true },
        { name: 'nb_km_way_0', label: 'Distance', heading: 'Distance', sortable: true, show: true },
        { name: 'creation_date', label: 'Date création', heading: 'Date création', sortable: true, show: false }
      ]
    }
  },
  methods: {
    refresh (payload) {
      this.filters.cityStart = payload.cityStart
      this.filters.cityEnd = payload.cityEnd
      this.filters.tonnage = payload.tonnage
    },
    startSearch (text) {
      this.search = text
    },
    selectAuthorizations (payload) {
      this.selected = payload
    },
    responseAdapter (authorizations) {
      authorizations.forEach(authorization => {
        authorization.weight = formatWeight(authorization)
        authorization.dimensions = formatDimensions(authorization)
        authorization.date_start = formatDate(authorization.date_start)
        authorization.date_end = formatDate(authorization.date_end)
        authorization.renewal_date_last_status = formatDate(authorization.renewal_date_last_status)
        authorization.renewal_date_ask = formatDate(authorization.renewal_date_ask)
        authorization.renewal_num = authorization.renewal_num || '--'
        authorization.nb_km_way_0 = Math.round(authorization.nb_km_way_0) + ' km'
        authorization.creation_date = formatDate(authorization.creation_date)
      })
      return authorizations
    },
    showAuthorization (click) {
      if (click.event.target.classList.contains('icon')
        || click.event.target.classList.contains('authorization-checkbox')
        || click.event.target.classList.contains('checkbox-cell')) {
        return
      }
      this.$router.push({
        name: 'authorizations.show',
        params: { id: click.row.id }
      })
    }
  }
}
</script>

<style language="scss">
  .authorizations-index-page {
    flex-direction: column;
  }

  .container-fluid {
    padding-top: 2em;
  }
</style>
