<template>
  <div class="datatable-pagination">
    <div @click="prev"
         :class="prevClasses">
      <i class="icon-previous"></i>
    </div>
    <div @click="next"
         :class="nextClasses">
      <i class="icon-next"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    }
  },
  computed: {
    prevDisabled () {
      return this.currentPage === 1
    },
    nextDisabled () {
      return this.totalPages === 0 || this.currentPage === this.totalPages
    },
    prevClasses () {
      return {
        'pagination-prev': true,
        'pagination-prev-disabled': this.prevDisabled
      }
    },
    nextClasses () {
      return {
        'pagination-next': true,
        'pagination-next-disabled': this.nextDisabled
      }
    }
  },
  methods: {
    prev () {
      if (!this.prevDisabled) {
        this.$emit('pagination', this.currentPage - 1)
      }
    },
    next () {
      if (!this.nextDisabled) {
        this.$emit('pagination', this.currentPage + 1)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .datatable-pagination {
    display: inline-block;
    font-size: 14px;

    @media (max-width: $breakpoint-md) {
      font-size: 12px;
    }
  }

  .pagination-prev,
  .pagination-next {
    display: inline-block;
    vertical-align: -1px;

    &:hover {
      cursor: pointer;
    }

    i {
      color: $red;
    }
  }

  .pagination-prev {
    margin-right: 30px;

    @media (max-width: $breakpoint-md) {
      margin-right: 20px;
    }
  }

  .pagination-prev-disabled,
  .pagination-next-disabled {
    &:hover {
      cursor: default;
    }

    i {
      color: $gray-light;
    }
  }
</style>
