<template>
  <div class="table-search">
    <i @click="focusSearch"
       class="icon-search"></i>
    <input v-model="text"
           @input="debounceSearch"
           id="table-search"
           type="text"
           placeholder="Rechercher">
  </div>
</template>

<script>
import { debounce } from 'debounce'

export default {
  data () {
    return {
      text: null
    }
  },
  methods: {
    focusSearch () {
      document.getElementById('table-search').focus()
    },
    search () {
      this.$emit('input', this.text)
    }
  },
  created () {
    this.debounceSearch()
  },
  computed: {
    debounceSearch () {
      return debounce(this.search, 200)
    }
  },
  watch: {
    text () {
      this.debounceSearch()
    }
  }
}
</script>

<style scoped lang="scss">
  .table-search {
    display: inline-block;
    height: 42px;
    font-size: 15px;
    border: 1px solid $red;
    border-radius: 3px;

    @media (max-width: $breakpoint-md) {
      height: 34px;
      font-size: 13px;
    }

    @media (max-width: $breakpoint-sm) {
      width: 49%;
    }

    @media (max-width: $breakpoint-xs) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  i {
    color: $red;
    font-size: 18px;
    vertical-align: -2px;
    padding: 0 15px;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: $breakpoint-md) {
      font-size: 15px;
      padding: 0 7px;
    }
  }

  input {
    border: none;
    background: $white;
    height: 100%;

    &:focus {
      outline: none;
    }
  }
</style>
