<template>
  <div v-if="isAdmin" class="top-bar-container">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-9">
          <div class="statistics">
            <div class="statistic">
              <div class="point point-blue"></div>
              <div class="statistic-number">{{ statistics['validated'] }}</div>
              <div class="statistic-text" v-html="validText"></div>
            </div>
            <div class="statistic">
              <div class="point point-orange"></div>
              <div class="statistic-number">{{ statistics['to be renewed'] }}</div>
              <div class="statistic-text">à renouveler</div>
            </div>
            <div class="statistic">
              <div class="point point-red"></div>
              <div class="statistic-number">{{ statistics['not valid'] }}</div>
              <div class="statistic-text">{{ notValidText }}</div>
            </div>
            <div class="statistic">
              <div class="point point-purple"></div>
              <div class="statistic-number">{{ statistics['ongoing input'] }}</div>
              <div class="statistic-text">en cours de finalisation</div>
            </div>
            <div class="statistic">
              <div class="point point-blue"></div>
              <div class="statistic-number">{{ statistics['waiting for renewal'] }}</div>
              <div class="statistic-text">en consultation</div>
            </div>
          </div>
        </div>
        <div class="col-md-3 top-bar-button-container">
		<div v-if="isAdmin">
        	  <router-link :to="{ name: 'authorizations.create' }"
                       class="btn btn-primary">
           	 Ajouter une autorisation
         	 </router-link>
      	  </div>
	</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuthorizationsStatistics } from '@/api/premat'

export default {
  data () {
    return {
	isAdmin : true,
      statistics: {
        'ongoing input': 0,
        'to be renewed': 0,
        'waiting for validation': 0,
        validated: 0,
        deleted: 0,
        'not valid': 0,
        'waiting for renewal': 0
      }
    }
  },
  computed: {
    validText () {
      return this.statistics.validated > 1 ? 'autorisations valides' : 'autorisation<br> valide'
    },
    notValidText () {
      return this.statistics['not valid'] > 1 ? 'non valides' : 'non valide'
    }
  },
  methods: {
    getStats () {
      getAuthorizationsStatistics()
        .then(response => {
          this.statistics = response.data
        })
        .catch(error => console.log(error))
    }
  },
  beforeMount() {
    let user = Object.values(JSON.parse(localStorage.getItem('user')))[0].login
    if(user === 'conducteur') {
    this.isAdmin = false
    }
  },
  mounted () {
        if (this.isAdmin) {
      this.getStats()
    }
  }
}
</script>

<style scoped lang="scss">
  .top-bar-container {
    border-top: 1px solid rgba($gray-text-light, .2);
    border-bottom: 1px solid rgba($gray-text-light, .2);
  }

  .top-bar-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .statistics {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // padding: 15px 0;
  }

  .statistic {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 3px;
  }

  .statistic div {
    display: inline-block;
  }

  .point {
    min-width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 12px;

    @media (max-width: $breakpoint-md) {
      min-width: 7px;
      height: 7px;
      margin-right: 7px;
    }
  }

  .point-green {
    background: $green;
  }

  .point-orange {
    background: $orange;
  }

  .point-yellow {
    background: $yellow;
  }

  .point-red {
    background: $red;
  }

  .point-purple {
    background: $purple;
  }

  .point-blue {
    background: $blue;
  }

  .statistic-number {
    margin-right: 15px;
    font-size: 33px;

    @media (max-width: $breakpoint-md) {
      font-size: 23px;
      margin-right: 7px;
    }
  }

  .statistic-text {
    font-size: 16px;
    // line-height: 1.2;

    @media (max-width: $breakpoint-md) {
      font-size: 13px;
      margin-right: 8px;
    }
  }

  .btn {
    padding: 5px 30px;

    @media (max-width: $breakpoint-md) {
      padding: 3px 15px;
    }

    @media (max-width: $breakpoint-sm) {
      margin-bottom: 10px;
    }
  }
</style>
